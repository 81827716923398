import serialize from 'serialize-javascript';

import { HttpUtils } from '../../utils/HttpUtils';
import { environment } from '../config/environment';
import { adBlockDetector } from './AdBlockerService';
import AdFreeService from './AdFreeService';
import AdsService from './AdsService';
import { Deferred } from './Deferred';
import { UrlService } from './UrlService';
import { MiscUtils } from '../../utils/MiscUtils';

const adLoadedDefer = new Deferred();

export class DisplayAdService {
    public static start() {
        if (UrlService.getQSParam(window.location.search, '__arkads') !== 'runnoads') {
            DisplayAdService.init().then(() => {
                AdsService.init();
                adBlockDetector.init();
            });
        } else {
            adLoadedDefer.resolve();
        }
    }

    public static init(): Promise<any> {
        let queryUrl = UrlService.getQSParam(location.search, environment.ADS_QUERY_PARAM);
        queryUrl = queryUrl ? `?${environment.ADS_QUERY_PARAM}=${serialize(queryUrl).replaceAll('"', '')}` : '';
        const libraryPath = `${environment.DISPLAY_ADS}${queryUrl}`;
        const adsByGoogleUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        const adsByGoogleToken = 'ca-pub-1748410580814249';
        Promise.all([HttpUtils.loadScript(adsByGoogleUrl, adsByGoogleToken), HttpUtils.loadScript(libraryPath)])
            .then((_) => adLoadedDefer.resolve())
            .catch((_) => adLoadedDefer.reject())
            .finally(() => adBlockDetector.updateAdsBlocked());

        return adLoadedDefer.promise;
    }

    public static startVignette() {
        const gamesForAbcGoogle = ['tic-tac-toe'];
        const dataFreq = !!gamesForAbcGoogle.some((slug) => (window as any).location.href.includes(`/${slug}`));
        const isSpecialPage = MiscUtils.isPagesToRemoveGoogleVignette(window.location.pathname, window.location.origin);

        if (!isSpecialPage && AdFreeService.showVignette()) {
            const script = document.createElement('script');
            script.defer = true;
            script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
            script.setAttribute('data-ad-client', 'ca-pub-1748410580814249');
            if (dataFreq) script.setAttribute('data-ad-frequency-hint', '30s');
            document.body.appendChild(script);
        }
    }
}
