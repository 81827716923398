import React from 'react';

import { batch, useDispatch, useSelector } from 'react-redux';

import styles from './styles.css';
import { StringUtils } from '../../../../utils/StringUtils';
import { IGame } from '../../../models/Game/Game';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO, PagesData } from '../../../models/PagesData';
import { CategoryCard } from '../../../molecules/Cards/CategoryCard/CategoryCard';
import { Analytics } from '../../../services/Analytics/Analytics';
import { GameService } from '../../../services/GameService';
import { setSideMenuOpened } from '../../../store/ducks/layout';
import { setMobileNavigationUxRedesignedActiveButton } from '../MobileNavigationUxRedesign.ducks';

const categoriesList: string[] = [
    'all',
    'best', // 1 row
    'daily games',
    'new', // 2 row
    'mahjong',
    'solitaire', // 3 row
    'crosswords',
    'sudoku', // 4 row
];

export const CategoriesBody = (props: { games: IGame[] }) => {
    const dispatch = useDispatch();
    const currentLang = useSelector((state) => state.currentLang);
    const pages = useSelector((state) => PagesData.getOnlyCategoriesPages(state.pages));
    const games = props.games;
    const categories = getCategories(pages, currentLang, games)
        .filter((category) => {
            return categoriesList.map((name) => name.toLowerCase()).includes(category?.name?.en?.toLowerCase?.());
        })
        .sort((a, b) => {
            return (
                categoriesList.indexOf(a?.name?.en?.toLowerCase?.()) -
                categoriesList.indexOf(b?.name?.en?.toLowerCase?.())
            );
        })
        .map((category) =>
            category?.name?.en === 'All' ? { ...category, name: { ...category.name, en: 'All Games' } } : category
        );

    return (
        <>
            {categories.map((category) => {
                return (
                    <div className={styles.Button} key={category.name[currentLang]}>
                        <CategoryCard
                            cardType={category.pageName}
                            categorySlug={category.slug[currentLang]}
                            caption={category.name[currentLang]}
                            onClick={async () => {
                                await Analytics.trackEvent(
                                    Analytics.mobileNavMenuUxRedesigned.bottomNavMenu(category.name[currentLang])
                                );
                                batch(() => {
                                    dispatch(setMobileNavigationUxRedesignedActiveButton(null));
                                    dispatch(setSideMenuOpened(false));
                                });
                            }}
                        />
                    </div>
                );
            })}
        </>
    );
};

function getCategories(
    categories: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[],
    language: string,
    games: IGame[]
): CategoryPageSEO[] {
    const bag = categories.filter((c) => (c as CategoryPageSEO).category);

    let result = [];

    bag.forEach((cat) => {
        const categoryGames = games.filter((game) =>
            game.categories.find((gameCategory) => StringUtils.equalIgnoreCase(gameCategory, cat.name[language]))
        );

        if (GameService.filterGamesByLang(categoryGames, language).length > 0 && cat.name[language]) {
            result.push(cat);
        }
    });

    result = result.sort((p1, p2) => p1.order - p2.order);
    return result;
}
