export interface GeneralPageSEO {
    order: number;
    name: LocalizedText;
    slug: LocalizedText;
    navigationLabel: LocalizedText;
    breadcrumbLabel: LocalizedText;
    pageName: string;
    onPageTitle: LocalizedText;
    onPageDescription: LocalizedText;
    metaHTMLTitle: LocalizedText;
    metaHTMLDescription: LocalizedText;
}

// TODO: i18 move text
export interface CategoryPageSEO extends GeneralPageSEO {
    category: boolean;
    image: string;
    topHeaderH1: LocalizedText;
    headerBackgroundColor: string;
    headerBackgroundColor2: string;
    headerBoxShadow: string;
    pageDescriptionTitle: LocalizedText;
    supportedLocalesNonEn: [];
    sidebarLabel?: LocalizedText;
}

export interface CoBrandedPageSEO extends GeneralPageSEO {
    themeColor: string;
    logo: string;
    heroTitle: LocalizedText;
    heroText: LocalizedText;
    heroButtonColor: string;
    heroButtonTextColor: string;
    heroButtonHoverColor: string;
    heroButtonTextHoverColor: string;
    backgroundImage: string;
    externalLink: string;
    externalLinkText: LocalizedText;
    sliderTitle: LocalizedText;
    sliderGames: [];
    promoImage: string;
    promoTitle: LocalizedText;
    promoDescription: LocalizedText;
    promoLink: string;
    promoLinkText: LocalizedText;
    backgroundColor: string;
    cookieValue: string;
}

export interface LocalizedText {
    en: string;
    fr: string;
    de: string;
    es: string;
}

export const CategoryLocalizedRoute = {
    en: 'free-online-games',
    fr: 'jeux-gratuits',
    de: 'free-online-games',
    es: 'free-online-games',
};

export const AllCategoriesLocalizedRoute = {
    en: 'categories',
    fr: 'categorie',
    de: 'categories',
    es: 'categories',
};

export const GiftCardLocalizedRoute = {
    en: 'gift-card',
    fr: 'gift-card',
    de: 'gift-card',
    es: 'gift-card',
};
export const AboutUsPageLocalizedRoute = {
    en: 'our-story',
    fr: 'notre-histoire',
    de: 'our-story',
    es: 'our-story',
};
export const CoBrandedPageLocalizedRoute = {
    en: 'co-branded',
    fr: 'co-branded',
    de: 'co-branded',
    es: 'co-branded',
};
export const BlogArchiveLocalizedRoute = {
    en: 'blog',
    fr: 'blog',
    de: 'blog',
    es: 'blog',
};
export const GameSubmissionDisclaimerPageRoute = {
    en: 'game-submission-disclaimers',
    fr: 'game-submission-disclaimers',
    de: 'game-submission-disclaimers',
    es: 'game-submission-disclaimers',
};
export const SiteMapLocalizedRoute = {
    en: 'site-map',
    fr: 'site-map',
    de: 'site-map',
    es: 'site-map',
};

const defaultLocalizedText: LocalizedText = {
    en: '',
    fr: '',
    de: '',
    es: '',
};

export class PagesData implements CategoryPageSEO {
    order: number;

    name: LocalizedText;

    slug: LocalizedText;

    navigationLabel: LocalizedText;

    breadcrumbLabel: LocalizedText;

    pageName: string;

    onPageTitle: LocalizedText;

    onPageDescription: LocalizedText;

    metaHTMLTitle: LocalizedText;

    metaHTMLDescription: LocalizedText;

    category: boolean;

    image: string;

    topHeaderH1: LocalizedText;

    headerBackgroundColor: string;

    headerBackgroundColor2: string;

    headerBoxShadow: string;

    pageDescriptionTitle: LocalizedText;

    supportedLocalesNonEn: [];

    sidebarLabel: LocalizedText;

    themeColor: string;

    logo: string;

    headerTitle: LocalizedText;

    externalLink: string;

    externalLinkText: LocalizedText;

    backgroundImage: string;

    sliderTitle: LocalizedText;

    sliderGames: [];

    promoImage: string;

    promoTitle: LocalizedText;

    promoDescription: LocalizedText;

    promoLink: string;

    promoLinkText: LocalizedText;

    cookieValue: string;

    heroTitle: LocalizedText;

    heroText: LocalizedText;

    heroButtonColor: string;

    heroButtonTextColor: string;

    heroButtonHoverColor: string;

    heroButtonTextHoverColor: string;

    constructor(pageData: CategoryPageSEO | GeneralPageSEO) {
        this.order = pageData.order;
        this.name = { ...defaultLocalizedText, ...pageData.name };
        this.slug = { ...defaultLocalizedText, ...pageData.slug };
        this.navigationLabel = {
            ...defaultLocalizedText,
            ...pageData.navigationLabel,
        };
        this.breadcrumbLabel = {
            ...defaultLocalizedText,
            ...pageData.breadcrumbLabel,
        };
        this.pageName = pageData.pageName;
        this.onPageTitle = { ...defaultLocalizedText, ...pageData.onPageTitle };
        this.onPageDescription = {
            ...defaultLocalizedText,
            ...pageData.onPageDescription,
        };
        this.metaHTMLTitle = {
            ...defaultLocalizedText,
            ...pageData.metaHTMLTitle,
        };
        this.metaHTMLDescription = {
            ...defaultLocalizedText,
            ...pageData.metaHTMLDescription,
        };
        this.category = (pageData as CategoryPageSEO).category;
        this.image = (pageData as CategoryPageSEO).image;
        this.topHeaderH1 = {
            ...defaultLocalizedText,
            ...(pageData as CategoryPageSEO).topHeaderH1,
        };
        this.headerBackgroundColor = (pageData as CategoryPageSEO).headerBackgroundColor;
        this.headerBackgroundColor2 = (pageData as CategoryPageSEO).headerBackgroundColor2;
        this.headerBoxShadow = (pageData as CategoryPageSEO).headerBoxShadow;
        this.pageDescriptionTitle = {
            ...defaultLocalizedText,
            ...(pageData as CategoryPageSEO).pageDescriptionTitle,
        };
        this.supportedLocalesNonEn = (pageData as CategoryPageSEO).supportedLocalesNonEn;
        this.sidebarLabel = {
            ...defaultLocalizedText,
            ...(pageData as CategoryPageSEO).sidebarLabel,
        };
        this.themeColor = (pageData as CoBrandedPageSEO).themeColor;
        this.logo = (pageData as CoBrandedPageSEO).logo;
        // TODO: remove logo/background/any other images from cobranded json file and from here
        this.heroTitle = {
            ...defaultLocalizedText,
            ...(pageData as CoBrandedPageSEO).heroTitle,
        };
        this.heroText = {
            ...defaultLocalizedText,
            ...(pageData as CoBrandedPageSEO).heroText,
        };
        this.heroButtonColor = (pageData as CoBrandedPageSEO).heroButtonColor;
        this.heroButtonTextColor = (pageData as CoBrandedPageSEO).heroButtonTextColor;
        this.heroButtonHoverColor = (pageData as CoBrandedPageSEO).heroButtonHoverColor;
        this.heroButtonTextHoverColor = (pageData as CoBrandedPageSEO).heroButtonTextHoverColor;
        this.backgroundImage = (pageData as CoBrandedPageSEO).backgroundImage;

        this.externalLink = (pageData as CoBrandedPageSEO).externalLink;
        this.externalLinkText = {
            ...defaultLocalizedText,
            ...(pageData as CoBrandedPageSEO).externalLinkText,
        };
        this.sliderTitle = (pageData as CoBrandedPageSEO).sliderTitle;
        this.sliderGames = (pageData as CoBrandedPageSEO).sliderGames;
        this.promoImage = (pageData as CoBrandedPageSEO).promoImage;
        this.promoTitle = {
            ...defaultLocalizedText,
            ...(pageData as CoBrandedPageSEO).promoTitle,
        };
        this.promoDescription = {
            ...defaultLocalizedText,
            ...(pageData as CoBrandedPageSEO).promoDescription,
        };
        this.promoLink = (pageData as CoBrandedPageSEO).promoLink;
        this.promoLinkText = {
            ...defaultLocalizedText,
            ...(pageData as CoBrandedPageSEO).promoLinkText,
        };
        this.cookieValue = (pageData as CoBrandedPageSEO).cookieValue;
    }

    static getPages(
        pagesData: (CategoryPageSEO | GeneralPageSEO | CoBrandedPageSEO)[]
    ): (CategoryPageSEO | GeneralPageSEO | CoBrandedPageSEO)[] {
        return pagesData.map((item) => new PagesData(item));
    }

    static getOnlyNonCategoriesPages(
        pagesData: (CategoryPageSEO | GeneralPageSEO | CoBrandedPageSEO)[]
    ): GeneralPageSEO[] {
        return pagesData.filter((item) => !(item as CategoryPageSEO).category).map((item) => new PagesData(item));
    }

    static getOnlyCategoriesPages(pagesData: (CategoryPageSEO | GeneralPageSEO)[]): CategoryPageSEO[] {
        return pagesData.filter((item) => (item as CategoryPageSEO).category).map((item) => new PagesData(item));
    }

    static getOnlyCoBrandedPages(pagesData: CoBrandedPageSEO[]) {
        return pagesData.filter((item) => (item as CoBrandedPageSEO).cookieValue).map((item) => new PagesData(item));
    }
}
