import { LocalStorageService } from '../LocalStorage';

// Add this interface to extend the Window type
declare global {
    interface Window {
        __tcfapi?: (command: string, version: number, callback: (tcData: any, success: boolean) => void) => void;
    }
}

class GDPRMediator {
    activeProvider: 'inMobi|Quantcast' | null = null;
    isGDPRUser: boolean = true;
    isAnalyticsEnabled: boolean = false;
    readonly gdprCookieConsent = 'gdprCookieConsent';

    async init() {
        if (window.__tcfapi) {
            // if script loaded we have gdpr support and can set activeprovider
            this.isGDPRUser = true;
            this.activeProvider = 'inMobi|Quantcast';
            window.__tcfapi('addEventListener', 2, this.onChanges);
        } else {
            // if script failed to load we have no gdpr support
            this.isGDPRUser = false;
            this.activeProvider = null;
            // we should disable analytics because we don't know where user is
            this.isAnalyticsEnabled = false;
        }
    }

    onChanges = () => {
        window.__tcfapi('getTCData', 2, (tcData) => {
            if (tcData.gdprApplies) {
                const consent = tcData.purpose.consents[1] && tcData.vendor.consents[11];

                this.setGdprCookieConsent(consent);
                this.isGDPRUser = true;
                this.isAnalyticsEnabled = false;
            } else {
                this.setGdprCookieConsent(false);
                this.isGDPRUser = false;
                this.isAnalyticsEnabled = true; // Enable analytics if GDPR does not apply
            }
        });
    };

    isBlockedByGdprConsent() {
        if (!this.isGDPRUser) {
            return false;
        }

        return !this.getGdprCookieConsent();
    }

    consentBy3rdPartyProvider() {
        return {
            CONSOLE: true,
            GOOGLE_ANALYTICS: this.isAnalyticsEnabled,
            APP_INSIGHTS: this.getGdprCookieConsent(),
            ALL: false,
        };
    }

    private setGdprCookieConsent(consent: boolean) {
        LocalStorageService.setItem(this.gdprCookieConsent, consent);
    }

    private getGdprCookieConsent = () => {
        const consent = LocalStorageService.getItem(this.gdprCookieConsent) === 'true';

        return consent;
    };
}

export const GDPRMediatorService = new GDPRMediator();
